import { httpRequest } from '@shared/api/http-request';
import api from '@shared/api/config';
import { formatExcelData } from '../utils/format-excel-data/index.format-excel-data';
import { setExcelData } from '../utils/set-excel-data/index.set-excel-data';
import { v4 as uuidv4 } from 'uuid';
import { addError, resetErrorByCell } from '../utils/error-engine/index.error-engine';

const { jtubesRoot } = api;

type Request = { payload: any; url: string; cell: string };

/*
	Simple batching engine, pushes promises that await resolution (up to the limit),
	and continues to push until there are no more requests
*/

const engine = {
	limit: 5,
	runningStack: <any>[],
	waitingStack: <any>[],
	async add(request: any) {
		return new Promise((resolve, reject) => {
			this.waitingStack.push(async () => {
				try {
					const result = await request();
					resolve(result);
				} catch (error) {
					reject(error);
				}
			});
			this.execute();
		});
	},
	async execute() {
		if (this.runningStack.length < this.limit && this.waitingStack.length > 0) {
			const nextRequest = this.waitingStack.shift();
			const requestId = uuidv4();
			this.runningStack.push({ nextRequest, requestId });
			try {
				await nextRequest();
			} finally {
				const mappedRunningStackIndex = this.runningStack
					.map((runningRequest: any) => runningRequest.requestId)
					.indexOf(requestId);
				this.runningStack.splice(this.runningStack.indexOf(mappedRunningStackIndex, 1));
				this.execute();
			}
		}
	}
};

export const handleQuery = async (request: Request) => {
	resetErrorByCell(request?.cell);

	return engine.add(async () => {
		try {
			const { payload, url, cell } = request;
			const response = await httpRequest<any, null, 'isPromise'>({
				isPromise: true,
				method: 'post',
				url: `${jtubesRoot.url}/action/execute/${url}`,
				data: { data: { action_parameters: payload, format: 'excel' } },
				handleError: (err) => {
					console.error(err);
				}
			});
			const formattedData = formatExcelData(response);
			console.log('DATA', formattedData, cell, { payload, url, displayValue: formattedData.header[0] });
			setExcelData(formattedData, cell, { payload, url, displayValue: formattedData.header[0] });

			if (formattedData) {
				return formattedData;
			}

			return new Error();
		} catch (error: any) {
			// Different ways of getting errors, errors that we expect, more catastrophic errors, and then uncaught errors entierly.
			const iterableErrors = error?.response?.data?.data;
			const openJSONSchemaErrors = error?.response?.data?.errors;
			addError({
				data: { raw: error?.response?.data, iterableErrors, request, openJSONSchemaErrors },
				headers: error?.response?.headers
			});
			// eslint-disable-next-line
			await Excel.run(async (context) => {
				try {
					let comments = context.workbook.comments;
					if (openJSONSchemaErrors) {
						openJSONSchemaErrors.map((openJSONschemaError: any) => {
							const { status, title, detail } = openJSONschemaError;
							comments.add(request.cell, `${status}: ${title || 'Uncaught Error'} - ${detail}`);
						});
					} else if (iterableErrors) {
						let serverErrors: any = Object.values(iterableErrors);
						serverErrors.forEach((serverError: string) => {
							comments.add(request.cell, `${'Uncaught Error'} - ${serverError}`);
						});
					}

					await context.sync();
				} catch {
					// Step out and complete if theres an error with error handling lol
					await context.sync();
				}
			});
			console.group('%cError', 'color: red; font-size: 20px');
			console.error('Error: ', error);
			console.groupEnd();

			return error;
		}
	});
};
