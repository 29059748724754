// eslint-disable-next-line
/* global clearInterval, console, CustomFunctions, setInterval */

import { rebuildPayloadAsObject } from '../utils/rebuild-payload-as-object/index.rebuild-payload-as-object';
import { clearRangeFromId } from '../utils/clear-range-from-id/index.clear-range-from-id';
import { handleQuery } from './batchingEngine';

/**
 * @customfunction
 * @requiresAddress
 * @param payloadStringsArray
 * @param {CustomFunctions.Invocation}
 */

export async function runQuery(payloadStringsArray: string[][][], invocation: CustomFunctions.Invocation) {
	const initializeIsAuthenticated = localStorage.getItem('user');

	const isAuthenticated =
		initializeIsAuthenticated !== null && initializeIsAuthenticated !== '' && initializeIsAuthenticated !== '{}';
	const cell = invocation?.address || '';

	// eslint-disable-next-line
	const functionMeta = Office.context.document.settings.get(`${cell}`);
	// If we have information of a previously run query, blast out the old response using meta information
	if (functionMeta) {
		const parsedMeta = JSON.parse(functionMeta);
		const { values, header, sheetId } = parsedMeta;
		sheetId && clearRangeFromId(sheetId, values, header);
	}

	// Run query if authenticated, else return header ELSE return error for no previous successful query AND no user
	if (isAuthenticated) {
		// eslint-disable-next-line
		await OfficeRuntime.storage.removeItem('errorMessage');
		const cell = invocation?.address || '';

		const { payload, url } = await rebuildPayloadAsObject(payloadStringsArray, cell);
		// eslint-disable-next-line
		console.log(`%c## ${cell} REQUEST START - ${url} formatted payload ##`, 'color: blue; font-size: 20px');
		console.log('%cCE DEBUG', 'color: blue; font-size: 20px');
		console.table({ ...payload, url });
		console.log(`%c${url} formatted payload`, 'color: blue; font-size: 20px');
		console.log(JSON.stringify(payload));

		try {
			// Query executed here!
			const result: any = await handleQuery({ payload, url, cell });
			result && console.log('%cSUCCESS', 'color: green; font-size: 20px');
			console.log('result', result);
			console.log(`%c## REQUEST END - ${url} ##`, 'color: blue; font-size: 20px');

			if (result?.header?.[0]) {
				// eslint-disable-next-line
				return result?.header?.[0];
			}

			return new Error();
		} catch (error) {
			console.group('%cError', 'color: red; font-size: 20px');
			console?.error?.('Error: ', error);
			console.groupEnd();
			console.log(`%c## ${cell} REQUEST END - ${url}`, 'color: blue; font-size: 20px');

			return error;
		}
	} else {
		// eslint-disable-next-line
		Office.addin.showAsTaskpane();
		if (functionMeta) {
			const parsedMeta = JSON.parse(functionMeta);
			const { header } = parsedMeta;

			return header;
		}

		return 'No User and Errored Request';
	}
}

CustomFunctions.associate("RUNQUERY", runQuery);